import React, { FC, useMemo } from 'react';
import { graphql } from 'gatsby';
import { CampaignPageTypes } from '@shared/types/umbraco';
import { useBazaarVoice } from '@phx-husky/use-bazaarvoice';

import BodyRenderer from 'common/BodyRenderer';
import Layout from 'layout/Layout';
import { Container } from 'layout';
import PurposeBanner from 'components/PurposeBanner';
import MediaBanner from 'components/MediaBanner';
import CampaignSimpleBanner from 'components/CampaignSimpleBanner';
import GridList from 'components/GridList';
import DescriptionGridLink from 'common/DescriptionGridLink';
import CustomCarousel from 'components/CustomCarousel';
import DescriptionLinkVideo from 'common/DescriptionLinkVideo';
import InfoBanner from 'components/InfoBanner';
import WideBanner from 'components/WideBanner';
import TextSection from 'components/TextSection';
import SimpleBanner from 'components/SimpleBanner';
import InstructionsStepList from 'components/InstructionsStepList';
import SignUpBlock from 'components/SignUpBlock';
import ImageSection from 'components/ImageSection';
import ReceiptForm from 'components/ReceiptForm';
import SignUp from 'components/SignUp';
import VideoListSection from 'components/VideoListSection';
import RelatedArticles from 'components/RelatedArticles';
import CampaignBanner from 'components/CampaignBanner';
import IframeBlock from 'components/IframeBlock';
import FaqSection from 'components/FaqSection';
import SlimBanner from 'components/SlimBanner';
import DownloadList from 'components/DownloadList';
import ImageMapSection from 'components/ImageMapSection';
import ProductListingBanner from 'components/ProductListingBanner';
import CouponsSections from 'components/CouponsSections';
import PledgeSection from 'components/PledgeSection';
import WaterCalculator from 'components/WaterCalculator';
import SectionWithCarousel from 'components/SectionWithCarousel';
import QuestionsCarousel from 'components/SupportPage/QuestionsCarousel';
import QuestionsBlock from 'components/SupportPage/QuestionsBlock';
import VideoBackgroundSection from 'components/VideoBackgroundSection';
import WaterBanner from 'components/WaterBanner';
import EBook from 'components/EBook';
import PromoForm from 'components/PromoForm';
import ImageBanner from 'components/ImageBanner';
import RelatedCampaigns from 'components/RelatedCampaigns';
import BazaarvoiceReviewsSection from 'components/BazaarvoiceReviewsSection';
import HomeBanner from 'components/HomeBanner';
import CommunityConnectSection from 'components/CommunityConnectSection';
import ChallengeBanner from 'components/ChallengeBanner';
import RetailersSection from 'components/RetailersSection';
import Video from 'common/Video';
import TextBlock from 'common/TextBlock';
import SuperbowlBanner from 'components/SuperBowlBanner';
import VoteSection from 'components/VoteSection';
import ChallengeInfoSection from 'components/ChallengeInfoSection';
import WaterWasteBanner from 'components/WaterWasteBanner';
import WaterWasteVideo from 'components/WaterWasteVideo';
import SaveWaterProducts from 'components/SaveWaterProducts';
import RteSection from 'components/RteSection';

import { BV_SCRIPT_HOST } from 'utils/constants';

import './CampaignPage.scss';
import './CampaignPageOverride.scss';

const elements = {
  'Image Banner': ({ properties }, keyId) => <ImageBanner key={keyId} {...properties} />,
  'New Media Banner': ({ properties }, keyId) => <MediaBanner key={keyId} {...properties} />,
  'Signup Placeholder': ({ properties }, keyId) => <SignUp key={keyId} {...properties} />,
  'Image Section': ({ properties }, keyId) => <ImageSection key={keyId} {...properties} />,
  'Image Text Grid': ({ properties }, keyId) => <GridList key={keyId} {...properties} />,
  Partners: ({ properties }, keyId) => <DescriptionGridLink key={keyId} {...properties} />,
  Carousel: ({ properties }, keyId) => <CustomCarousel key={keyId} {...properties} />,
  'Description Link Video': ({ properties }, keyId) => (
    <DescriptionLinkVideo key={keyId} {...properties} />
  ),
  'Info Banner': ({ properties }, keyId) => <InfoBanner key={keyId} {...properties} />,
  'Info Banner Wide': ({ properties }, keyId) => <WideBanner key={keyId} {...properties} />,
  'Text Section': ({ properties }, keyId) => <TextSection key={keyId} {...properties} />,
  'Instructions List': ({ properties }, keyId) => (
    <InstructionsStepList key={keyId} properties={properties} />
  ),
  'Sign Up Block': ({ properties }, keyId) => <SignUpBlock key={keyId} {...properties} />,
  'Receipt Form': ({ properties }, keyId) => <ReceiptForm key={keyId} {...properties} />,
  'Video List Section': ({ properties }, keyId) => <VideoListSection key={keyId} {...properties} />,
  'Related Articles': ({ properties }, keyId) => <RelatedArticles key={keyId} {...properties} />,
  'Image Map Section': ({ properties }, keyId) => <ImageMapSection key={keyId} {...properties} />,
  'Iframe Block': ({ properties }, keyId) => <IframeBlock key={keyId} {...properties} />,
  'Promo Form': ({ properties }, keyId) => <PromoForm key={keyId} {...properties} />,
  'FAQ Section': ({ properties }, keyId) => <FaqSection key={keyId} {...properties} />,
  'Slim banner': ({ properties }, keyId) => <SlimBanner key={keyId} {...properties} />,
  'Download List': ({ properties }, keyId) => <DownloadList key={keyId} {...properties} />,
  'Product Listing Banner': ({ properties }, keyId) => (
    <ProductListingBanner key={keyId} {...properties} />
  ),
  'Coupons Section': ({ properties }, keyId) => <CouponsSections key={keyId} {...properties} />,
  'Pledge Section': ({ properties }, keyId) => <PledgeSection key={keyId} {...properties} />,
  'Video Background Section': ({ properties }, keyId) => (
    <VideoBackgroundSection key={keyId} {...properties} />
  ),
  'Water Calculator Section': ({ properties }, keyId) => (
    <WaterCalculator key={keyId} {...properties} />
  ),
  EBook: ({ properties }, keyId) => <EBook key={keyId} {...properties} />,
  'Section with carousel': ({ properties }, keyId) => {
    const { text, card, color, carouselMode, button, waiSettings, verticalCardMode } = properties;
    const questionCards = card.map((item) => item.properties);

    return (
      <SectionWithCarousel
        key={keyId}
        text={text}
        color={color}
        cardsBlock={
          carouselMode ? (
            <QuestionsCarousel
              questionCards={questionCards}
              ariaNext={waiSettings?.ariaNext}
              ariaPrev={waiSettings?.ariaPrev}
              isVerticalCardMode={verticalCardMode}
            />
          ) : (
            <QuestionsBlock questionCards={questionCards} isVerticalCardMode={verticalCardMode} />
          )
        }
        button={button}
      />
    );
  },
  'Related Campaigns': ({ properties }, keyId) => <RelatedCampaigns key={keyId} {...properties} />,
  'Bazaarvoice Reviews': ({ properties }, keyId) => (
    <BazaarvoiceReviewsSection
      key={keyId}
      title={properties.title}
      bvProductId={properties.bvProductId}
    />
  ),
  'Home Banner': ({ properties }, keyId) => <HomeBanner key={keyId} {...properties} />,
  'Community Connect Section': ({ properties }, keyId) => (
    <CommunityConnectSection key={keyId} {...properties} />
  ),
  'Challenge Banner': ({ properties }, keyId) => <ChallengeBanner key={keyId} {...properties} />,
  'Retailers Section': ({ properties }, keyId) => <RetailersSection key={keyId} {...properties} />,
  'Text Block': ({ properties }, keyId) => (
    <Container fluid>
      <TextBlock key={keyId} text={properties.text} />
    </Container>
  ),
  Video: ({ properties }, keyId) => (
    <Video className="campaign-page__video" key={keyId} {...properties} />
  ),
  'Super Bowl Banner': ({ properties }, keyId) => <SuperbowlBanner key={keyId} {...properties} />,
  'Vote Section': ({ properties }, keyId) => <VoteSection key={keyId} {...properties} />,
  'Challenge Info Section': ({ properties }, keyId) => (
    <ChallengeInfoSection key={keyId} {...properties} />
  ),
  'Water Waste Banner': ({ properties }, keyId) => <WaterWasteBanner key={keyId} {...properties} />,
  'Water Waste Video': ({ properties }, keyId) => <WaterWasteVideo key={keyId} {...properties} />,
  'Save Water Products': ({ properties }, keyId) => (
    <SaveWaterProducts key={keyId} {...properties} />
  ),
  'Rte Section': ({ properties }, keyId) => <RteSection key={keyId} {...properties} />,
};

const bannerElements = {
  'Top Banner': ({ properties }, keyId) => <SimpleBanner key={keyId} {...properties} />,
  'Purpose Banner': ({ properties }, keyId) => <PurposeBanner key={keyId} {...properties} />,
  'Simple Banner': ({ properties }, keyId) => (
    <CampaignSimpleBanner
      key={keyId}
      background={properties.background}
      header={properties.header}
    />
  ),
  'Campaign Banner': ({ properties }, keyId) => <CampaignBanner key={keyId} {...properties} />,
  'Water Banner': ({ properties }, keyId) => <WaterBanner key={keyId} {...properties} />,
};

const CampaignPage: FC<CampaignPageTypes.IProperties> = ({
  pageContext: { breadCrumbs, link, searchUrl, relatedArticlesLinks },
  data: {
    page: {
      nodes: [
        {
          banner,
          defaultCompositions,
          body,
          revisionIds,
          seoMetaKeywords,
          seoMetaTitle,
          seoMetaTitleTemplate,
          seoMetaDescription,
          seoExternalHreflangs,
          seoNoIndex,
          alternateUrls,
          ogImageUrl,
          disableSubNavigation,
        },
      ],
    },
    relatedArticles,
    relatedProducts,
    relatedCampaigns,
  },
}) => {
  const { singupFormBaner, waiSettings, siteSettings, header } = defaultCompositions;

  const bvScriptUrl = `${BV_SCRIPT_HOST}/${siteSettings?.bvScript}/bv.js`;
  useBazaarVoice(bvScriptUrl);

  const bannerImage =
    banner?.[0]?.properties?.foregroundImage?.[0]?.properties?.image ||
    banner?.[0]?.properties?.background?.[0]?.properties?.image;

  const imageUrl = ogImageUrl || bannerImage?.fallbackUrl;
  const imageAlt = bannerImage?.imageAlt;
  const isSubNavigationPresent = useMemo(() => {
    const activeNavItem = header?.navigation?.find(
      (item) =>
        item?.properties?.link?.[0] &&
        item.properties.link[0].properties.link[0].url !== '/' &&
        link.includes(item.properties.link[0].properties.link[0].url)
    );

    return !!activeNavItem?.properties?.subNavigation.length;
  }, []);

  return (
    <Layout
      {...{
        ...defaultCompositions,
        seoMetaKeywords,
        seoMetaTitle,
        seoMetaTitleTemplate,
        seoNoIndex,
        seoMetaDescription,
        seoExternalHreflangs,
        skipText: waiSettings?.skipText,
        link,
        searchUrl,
        alternateUrls,
        imageUrl,
        imageAlt,
        disableSubNavigation,
      }}
    >
      <BodyRenderer
        bodyData={banner}
        bodyStructure={bannerElements}
        bodyItemProps={{ breadCrumbs, isSubNavigationPresent }}
      />
      {body?.length ? (
        <div className="campaign-page__main-column">
          <BodyRenderer
            bodyData={body}
            bodyStructure={elements}
            bodyItemProps={{
              ...{
                singupFormBaner,
                waiSettings,
                siteSettings,
                revisionIds,
                relatedArticles,
                relatedArticlesLinks,
                relatedProducts,
                usePriceSpider: siteSettings?.usePriceSpider,
                relatedCampaigns,
                isEanProductId: siteSettings?.isEanProductId,
              },
            }}
          />
        </div>
      ) : null}
    </Layout>
  );
};

export const query = graphql`
  query allCampaignQuery(
    $link: String = ""
    $relatedArticlesLinks: [String]
    $relatedProductLinks: [String]
    $relatedCampaignsLinks: [String]
  ) {
    page: allCampaign(filter: { link: { eq: $link } }) {
      nodes {
        seoMetaTitle
        seoMetaTitleTemplate
        seoMetaDescription
        seoMetaKeywords
        seoNoIndex
        seoExternalHreflangs {
          key
          value
        }
        alternateUrls {
          lang
          url
          path
        }
        ogImageUrl
        banner {
          ...FragmentCampaignBanner
        }
        body {
          ... on TImageBanner {
            ...FragmentImageBanner
          }
          ... on TInfoBannerWide {
            ...FragmentWideBanner
          }
          ... on TPartners {
            ...FragmentPartners
          }
          ... on TNewMediaBanner {
            ...FragmentMediaBanner
          }
          ... on TCarousel {
            ...FragmentCustomCarousel
          }
          ... on TImageTextGrid {
            ...FragmenTImageTextGrid
          }
          ... on TDescriptionLinkVideo {
            ...FragmentDescriptionLinkVideo
          }
          ... on TInfoBanner {
            ...FragmentInfoBanner
          }
          ... on TTextSection {
            ...FragmentTextSection
          }
          ... on TInstructionsList {
            ...FragmentInstructionsList
          }
          ... on TSignupPlaceholder {
            ...FragmentSignUp
          }
          ... on TSignUpBlock {
            ...FragmentSignUpBlock
          }
          ... on TImageSection {
            ...FragmentImageSection
          }
          ... on TReceiptForm {
            ...FragmentReceiptForm
          }
          ... on TVideoListSection {
            ...FragmentVideoListSection
          }
          ... on TRelatedArticles {
            ...FragmentRelatedArticles
          }
          ... on TImageMapSection {
            ...FragmentImageMapSection
          }
          ... on TIframeBlock {
            ...FragmentIframeBlock
          }
          ... on TPromoForm {
            ...FragmentPromoForm
          }
          ... on TFAQSection {
            ...FragmentFaqSection
          }
          ... on TSlimbanner {
            ...FragmentSlimBanner
          }
          ... on TDownloadList {
            ...FragmentDownloadList
          }
          ... on TProductListingBanner {
            ...FragmentProductListingBanner
          }
          ... on TCouponsSection {
            ...FragmentCouponsSection
          }
          ... on TPledgeSection {
            ...FragmentPledgeSection
          }
          ... on TVideoBackgroundSection {
            ...FragmentVideoBackgroundSection
          }
          ... on TWaterCalculatorSection {
            ...FragmentWaterCalculator
          }
          ... on TSectionwithcarousel {
            ...FragmentSectionWithCarousel
          }
          ... on TEBook {
            ...FragmentTEBook
          }
          ... on TRelatedCampaigns {
            ...FragmentRelatedCampaigns
          }
          ... on TBazaarvoiceReviews {
            ...FragmentBazaarvoiceReviews
          }
          ... on THomeBanner {
            ...FragmentHomeBanner
          }
          ... on TCommunityConnectSection {
            ...FragmentCommunityConnectSection
          }
          ... on TChallengeBanner {
            ...FragmentChallengeBanner
          }
          ... on TRetailersSection {
            ...FragmentRetailersSection
          }
          ... on TVideo {
            ...FragmentVideoProperties
          }
          ... on TTextBlock {
            ...FragmentTextBlock
          }
          ... on TSuperBowlBanner {
            ...FragmentSuperBowlBanner
          }
          ... on TVoteSection {
            ...FragmentVoteSection
          }
          ... on TChallengeInfoSection {
            ...FragmentChallengeInfoSection
          }
          ... on TWaterWasteBanner {
            ...FragmentWaterWasteBanner
          }
          ... on TWaterWasteVideo {
            ...FragmentWaterWasteVideo
          }
          ... on TSaveWaterProducts {
            ...FragmentSaveWaterProducts
          }
          ... on TRteSection {
            ...FragmentRteSection
          }
        }
        revisionIds {
          BusinessId
          RevisionId
          ConsentDesc
          MandatoryInd
          ConsentAcceptedInd
          AgreementDate
          ActivityDate
        }
        defaultCompositions {
          footer {
            ...FragmentFooter
          }
          header {
            ...FragmentHeader
          }
          siteSettings {
            ...FragmentSiteSettingsComposition
          }
          brandSettings {
            ...FragmentBrandSettingsComposition
          }
          singupFormBaner {
            ...FragmentSignUpBanner
          }
          waiSettings {
            skipText
            ariaPrev
            ariaNext
          }
          warning {
            ...FragmentWarning
          }
          signUpPopup {
            ...FragmentSignUpPopup
          }
          signUpFormPopup {
            ...FragmentSignUpFormPopup
          }
        }
        disableSubNavigation
      }
    }
    relatedArticles: allArticle(filter: { link: { in: $relatedArticlesLinks } }) {
      nodes {
        link
        tags {
          isCategory
          isLabel
          title
        }
        title
        lang
        date
        dateSufix
        image {
          properties {
            image {
              ...FragmentGatsbyProps
              gatsbyImage {
                childImageSharp {
                  fluid(maxWidth: 300) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            imageAlt
          }
        }
      }
    }
    relatedProducts: allProduct(filter: { link: { in: $relatedProductLinks } }) {
      nodes {
        link
        ean
        tags {
          isCategory
          isFamily
          color {
            label
          }
          isLabel
          title
        }
        title
        shortDescription
        cardDescription
        cardLink {
          url
        }
        images {
          properties {
            imageAlt
            image {
              ...FragmentGatsbyProps
              gatsbyImage {
                childImageSharp {
                  fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
    relatedCampaigns: allCampaign(filter: { link: { in: $relatedCampaignsLinks } }) {
      nodes {
        link
        cardInfo {
          properties {
            description
            image {
              properties {
                image {
                  ...FragmentGatsbyImageSm
                }
                imageAlt
              }
            }
          }
        }
        tags {
          isCategory
          title
        }
      }
    }
  }
`;

export default CampaignPage;
